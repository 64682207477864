<template>
    <v-row class="mt-3">
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <img alt="Vue logo" src="https://ikonoseguros.co/wp-content/uploads/2024/05/salud.png"  height="460">
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-card
                class="mx-auto pa-4"
            >
                <v-card-title>Salud</v-card-title>
                <v-card-text>
                    <v-form v-model="valid">
                        <v-row class="mx-0 m-form">
                            <v-col cols="12" sm="12">
                                <v-text-field
                                   v-model="formData.Nombre"
                                   label="Nombre"
                                   dense
                                   color="primary"
                                   :rules="[(v) => !!v || 'Campo Requerido']"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12">
                                <v-text-field
                                   v-model="formData.Apellidos"
                                   label="Apellidos"
                                   dense
                                   color="primary"
                                   :rules="[(v) => !!v || 'Campo Requerido']"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12">
                                <v-text-field
                                   v-model="formData.Telefono"
                                   label="Celular"
                                   dense
                                   color="primary"
                                   :rules="[(v) => !!v || 'Campo Requerido']"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12">
                                <v-text-field
                                    v-model="formData.email"
                                    :rules="emailRules"
                                    label="Correo electrónico"
                                    required
                                ></v-text-field>
                            </v-col>

                            <div class="d-flex flex-column justify-center w-100">
                                <v-btn
                                   block
                                   elevation="2"
                                   class="mb-2 text-capitalize"
                                   color="primary"
                                   height="50"
                                   @click="submitForm"
                                   :disabled="!valid"
                                > Continuar</v-btn>
                                <span class="text-center">Al presionar continuar, estas aceptando los “términos y condiciones” <br> y las políticas de datos personales</span>
                            </div>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
            <v-overlay :value="overlay">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
        </v-col>
    </v-row>
  </template>
  
  <script>
  
  export default {
    name: 'HealthPolicyForm',
    data () {
        return {
            valid: false,
            name: '',
            lastname: '',
            phone: '',
            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            formData: {
                Nombre: '',
                Apellidos: '',
                Telefono: '',
                email: ''
            },
            formTitle: 'Formulario de vida y salud',
            overlay: false,
        }
    },
    methods:{
        async submitForm() {
            this.overlay = true
            const formDataToSend = {
                formTitle: this.formTitle,
                fields: this.formData
            };
            try {
                if (this.valid){
                    const response = await fetch('https://ikonoseguros.co//wp-json/contact-form/v1/send-email', {
                    method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formDataToSend),
                    });
                    
                    if (response.ok) {
                        console.log('Mensaje enviado correctamente');
                        this.overlay = false
                        this.continueAction()
                    } else {
                        console.log('Hubo un problema al enviar el mensaje');
                        alert('Hubo un problema al enviar el mensaje')
                    }
                }
            } 
            catch (error) {
                console.log('Error de conexión');
            }
        },
        continueAction(){
            this.$emit('continue-action')
        },
    }
  }
</script>
  
<style lang="sass" >
.v-stepper__header
    box-shadow: none
.v-stepper.auto-steper
    border: none !important
    background: transparent !important
    .v-stepper__items
        border: thin solid rgba(0, 0, 0, 0.12)
        border-radius: 6px
        background: #FFFFFF
.w-100
    width: 100% !important
</style>