<template>
    <v-row class="mt-3">
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <img alt="Vue logo" src="https://ikonoseguros.co/wp-content/uploads/2024/05/Motos.png" width="570" height="460">
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-stepper v-model="e1" outlined rounded class="auto-steper">
                <v-stepper-header>
                    <v-stepper-step
                        :complete="e1 > 1"
                        step="1"
                    >
                        Datos de la Moto
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="e1 > 2"
                        step="2"
                    >
                        Datos personales
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card
                          class=""
                          elevation="0"
                        >
                            <StepData :idNumber="'Moto'" :formData.sync="formData" @update-form-data="updateFormData" @first-form="firstForm"/>
                        </v-card>

                        <div class="d-flex justify-center">
                            <v-btn color="primary" @click="e1 = 2" class="ma-2 text-capitalize" width="220" :disabled="!isFirstFormValid">
                                Siguiente
                            </v-btn>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card
                            elevation="0"
                        >
                            <UserInfo :formData.sync="formData" @update-form-data="updateFormData" @second-form="secondForm"/>
                        </v-card>

                        <div class="d-flex justify-center">
                            <v-btn 
                               color="primary" 
                               outlined
                               @click="e1 = 1"
                               class="ma-2 text-capitalize" 
                               width="200"
                            >
                                Atras
                            </v-btn>

                            <v-btn
                               color="primary"
                               @click="submitForm"
                               class="ma-2 text-capitalize" 
                               width="200"
                               :disabled="!isSecondFormValid"
                            >
                                Cotizar
                            </v-btn>
                        </div>
                        <span class="text-center w-100 d-block mt-5 mb-5">Al presionar continuar, estas aceptando los “términos y condiciones” <br> y las políticas de datos personales</span>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
            <v-overlay :value="overlay">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
        </v-col>
    </v-row>
  </template>
  
  <script>
  import StepData from '@/components/formAutos/StepData.vue';
  import UserInfo from '@/components/formAutos/UserInfo.vue';
  export default {
    name: 'MotorcycleInsuranceForm',
    components: { StepData, UserInfo},
    props: {numbertab: Number},
    data () {
        return {
            e1: 1,
            formData: {
                marca: '',
                linea: '',
                año: '',
                vehiculos_cero_kilometros: '',
                nombre: '',
                celular: '',
                email: '',
            },
            formTitle: 'Formulario de Moto',
            overlay: false,
            isFirstFormValid: false,
            isSecondFormValid: false,
        }
    },
    methods:{
        updateFormData(updatedFields) {
            // Mezclar los datos actualizados con el formData actual
            this.formData = { ...this.formData, ...updatedFields };
        },
        async submitForm() {
            if (this.isSecondFormValid && this.isFirstFormValid) {
                this.overlay = true
                const formDataToSend = {
                    formTitle: this.formTitle,
                    fields: this.formData
                };
                try {
                    
                    const response = await fetch('https://ikonoseguros.co//wp-json/contact-form/v1/send-email', {
                    method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formDataToSend),
                    });
                    
                    if (response.ok) {
                        console.log('Mensaje enviado correctamente');
                        this.overlay = false
                        this.continueAction()
                    } else {
                        console.log('Hubo un problema al enviar el mensaje');
                        alert('Hubo un problema al enviar el mensaje')
                    }
                    
                } 
                catch (error) {
                    console.log('Error de conexión');
                }
            }
        },
        continueAction(){
            this.$emit('continue-action')
        },
        firstForm(isValid) {
            this.isFirstFormValid = isValid;
        },
        secondForm(isValid) {
            this.isSecondFormValid = isValid;
        },
    }
  }
</script>
  
<style lang="sass" >
.v-stepper__header
    box-shadow: none
.v-stepper.auto-steper
    border: none !important
    background: transparent !important
    .v-stepper__items
        border: thin solid rgba(0, 0, 0, 0.12)
        border-radius: 6px
        background: #FFFFFF
</style>