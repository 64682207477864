<template>
    <v-container class="AlliedCompanies">
        <v-row class="">
            <v-col cols="12" sm="12" class="text-center">
                <h2 class="mb-3">Aseguradoras Aliadas</h2>
                <p>Trabajamos con las mejores aseguradoras del país </p>
            </v-col>
        </v-row>
        <v-row class="">
            <v-col cols="12" class="d-flex justify-center">
                <div class="mx-sm-4 mx-md-12">
                    <img alt="ssb-logo" src="https://ikonoseguros.co/wp-content/uploads/2024/08/logo_SBS.png" style="min-width: 200px;">
                </div>

                <div class="mx-sm-4 mx-md-12">
                    <img alt="HDI logo" src="https://ikonoseguros.co/wp-content/uploads/2024/09/liberty-seguros.svg" width="110">
                </div>

                <div class="mx-sm-4 mx-md-12">
                    <img alt="liberty logo" src="https://ikonoseguros.co/wp-content/uploads/2024/09/logo-seguros-mundial.svg" width="220">
                </div>
                
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
    export default {
        name: "AlliedCompanies",
        data() {
            return {
            };
        },
        created() {},
        mounted() {},
        computed: {
        
        },
    };
</script>
  
<style lang="scss" >
</style>