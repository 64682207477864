<template>
    <v-container class="StepData">
      <v-row class="">
        <v-col cols="12" sm="12">
          <v-form v-model="valid">
            <v-row class="mx-0 m-form">
              
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="localFormData.marca"
                  :label="labelText"
                  dense
                  color="primary"
                  @input="updateField"
                  :rules="rules"
                  required
                ></v-text-field>
              </v-col>
              
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="localFormData.linea"
                  :label="lineText"
                  dense
                  color="primary"
                  @input="updateField"
                  :rules="rules"
                  required
                ></v-text-field>
              </v-col>
              
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="localFormData.año"
                  label="Año"
                  dense
                  color="primary"
                  @input="updateField"
                  :rules="rules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-checkbox
                  v-model="localFormData.vehiculos_cero_kilometros"
                  label="¿El vehículo es 0 kilometros?"
                  class="mt-0"
                  @change="updateField"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>

  export default {
    name: "StepData",
    props: { 
      formData: Object,
      idNumber: String
    },
    data() {
        return {
            valid: false,
            localFormData: { ...this.formData },
            rules: [
                value => !!value || 'Campo requerido.',
            ],
        };
    },
    created() {},
    mounted() {},
    computed: {
      labelText() {
        if (this.idNumber === 'cart') {
          return 'Marca del Auto';
        } else if (this.idNumber === 'Moto') {
          return 'Marca de Moto';
        }
        return 'Marca'; 
      },
      lineText() {
        if (this.idNumber === 'cart') {
          return 'Línea del Vehículo';
        } else if (this.idNumber === 'Moto') {
          return 'Línea de la Moto';
        }
        return 'Marca'; 
      }
    },
    methods: {
        updateField() {
            if(this.valid){
                this.$emit('update-form-data', {
                  marca: this.localFormData.marca,
                  linea: this.localFormData.linea,
                  año: this.localFormData.año,
                  vehiculos_cero_kilometros: this.localFormData.vehiculos_cero_kilometros
              });
            }
        },
    },
    watch: {
        valid(val) {
            this.$emit('first-form', val);
        }
    },
  };
  </script>
  
  <style lang="scss" >
  </style>