<template>
    <div>
        <v-container class="testimonials-section">
            <v-row class="">
                <v-col cols="12" sm="12" class="text-center">
                    <h2 class="mb-3">¿Qué opinan nuestros clientes?</h2>
                    <p></p>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="testimonials-section">
            <v-row class="justify-center" >
                <v-col cols="12" sm="12" class="d-flex justify-center" style="flex-wrap: wrap;">
                    <v-card 
                    v-for="(item, index) in items" :key="index" 
                        width="355" 
                        class="mx-3 my-3"
                        outlined
                        style="border: 1px solid #C1CEDB"
                    >
                        <v-card-title>
                            <v-list style="background: transparent !important;">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <img v-if="item.img"
                                            :src="item.img"
                                            :alt="item.name"
                                        >
                                        <v-avatar v-if="item.icon" color="#191769">
                                            <span class="white--text ">{{item.icon}}</span>
                                        </v-avatar>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                                        <v-list-item-subtitle class="primary--text">{{ item.email }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card-title>
                        
                        <v-card-text>
                            {{ item.text }}
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>  
</template>
  
<script>
    export default {
        name: "TestimonialsSection",
        data() {
            return {
                items: [
                    {
                        name: 'Milena Arias',
                        img: '',
                        email: '',
                        text: "Quiero expresar mi más sincero agradecimiento por el excelente servicio de asesoría y venta del seguro para mi carro. Tu profesionalismo y dedicación hicieron que todo el proceso fuera claro y sencillo. Aprecio mucho su paciencia y disposición para responder todas mis preguntas y asegurarme de que entendiera todas las opciones disponibles. Vale, Gracias por tu apoyo y por hacer de esta experiencia algo positivo y sin complicaciones. Sin duda, recomendaré tus servicios a amigos y familiares.",
                        icon: 'ML'
                    },
                    {
                        name: 'Zharick Naranjo',
                        img: '',
                        email: '',
                        text: "Me encanto tu acompañamiento, lo diligente que eres y lo buena asesora, no le quedan a uno dudas de nada 🙌🏽mil gracias",
                        icon: 'ZN'
                    },
                    {
                        name: 'Yonatan Rincón',
                        img: '',
                        email: '',
                        text: "¡Muchas gracias por tu atención! Todo estuvo muy claro y me sentí muy bien atendido. Eres realmente amable y profesional. Estoy muy contento con mi nuevo seguro de moto. ¡Gracias nuevamente por hacerlo tan fácil y comprensible",
                        icon: 'YR'
                    },
                ]
            };
        },
        created() {},
        mounted() {},
        computed: {},
    };
</script>
  
<style lang="scss" >
</style>