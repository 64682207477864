<template>
    <v-container class="StepData">
      <v-row class="">
        <v-col cols="12" sm="12">
            <v-form v-model="valid">
                <v-row class="mx-0 m-form">
                    <v-col cols="12" sm="12">
                        <v-text-field
                            v-model="localFormData.nombre"
                            label="Nombre"
                            dense
                            color="primary"
                            @input="updateField"
                            :rules="rules"
                            required
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                        <v-text-field
                            v-model="localFormData.celular"
                            label="Celular"
                            dense
                            color="primary"
                            @input="updateField"
                            :rules="rules"
                            required
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                        <v-text-field
                            v-model="localFormData.email"
                            :rules="emailRules"
                            label="Correo electrónico"
                            required
                            @input="updateField"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "UserInfo",
    props: { formData: Object, },
    data() {
      return {
        valid: false,
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
        rules: [
            value => !!value || 'Campo requerido.',
        ],
        localFormData: { ...this.formData },
      };
    },
    created() {},
    mounted() {},
    computed: {
      
    },
    methods: {
        updateField() {
            this.$emit('update-form-data', {
                nombre: this.localFormData.nombre,
                celular: this.localFormData.celular,
                email: this.localFormData.email,
            });
        },
    },
    watch: {
        valid(val) {
            this.$emit('second-form', val);
        }
    },
  };
  </script>
  
  <style lang="scss" >
  </style>